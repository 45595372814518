@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');


* {
    font-family: 'Nunito', 'Roboto', sans-serif;

}


.cs-fnt-manrope {
    font-family: 'Nunito', 'Manrope', sans-serif;
}

.cs-fnt-manrope {
    font-family: 'Nunito', 'Open Sans', sans-serif;
}

.cs-fnt-ibm {
    font-family: 'Nunito', 'IBM Plex Sans Thai', sans-serif;
}

.cs-main-title {
    font-size: 24px !important;
}

.cs-main-title-2 {
    font-size: 20px !important;
}

.cs-title {
    font-size: 20px !important;
}

#select-customer,
#select-language,
#select-unit-system {
    font-size: 16px !important;
    font-weight: 300 !important;
}

.cs-sub-title {
    font-size: 18px !important;
}

.res-fnt-20 {
    font-size: 20px !important;
}

.res-fnt-18 {
    font-size: 18px !important;
}

.cs-content,
label {
    font-size: 12px !important;
}

div {
    font-size: 12px !important;
}

div.ant-modal-confirm-content {
    font-size: 17px !important;
}


@media screen and (min-width: 1440px) {

    .cs-medium {
        font-weight: 500;
    }

    .cs-bold {
        font-weight: 900;
    }

    .cs-main-title {
        font-size: 32px !important;
    }

    .cs-main-title-2 {
        font-size: 30px !important;
    }

    .cs-title {
        font-size: 24px !important;
    }

    .res-fnt-20 {
        font-size: 20px !important;
    }

    .res-fnt-24 {
        font-size: 24px !important;
    }


    .cs-sub-title {
        font-size: 20px !important;
    }


    .cs-content,
    label {
        font-size: 16px !important;
    }

    div {
        font-size: 16px !important;
    }

    div.ant-modal-confirm-content {
        font-size: 20px !important;
    }
}

.cs-heading,
.ant-btn {
    font-family: 'Nunito', 'Montserrat', sans-serif !important;
}

.cs-medium {
    font-weight: 500;
}

.cs-bold {
    font-weight: 900;
}


.screen-heading {
    font-family: 'Nunito', 'Manrope', sans-serif;
    font-weight: bold;
}

.single-screen>* {
    font-family: 'Nunito', 'Manrope', sans-serif;
    font-weight: 300;
}

.brand-header-modal>* {
    font-family: 'Nunito', 'IBM Plex Sans Thai', sans-serif !important;
}