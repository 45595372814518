.screen-container {
    height: calc(100% - 50px);
    padding: 10px;
    overflow: hidden;
}

.screen-container::-webkit-scrollbar {
    display: none;
}

.screen-container.compress {
    width: calc(100% - 300px);
}

.react-grid-layout {
    position: relative;
}

.hierarchy-context {
    margin: 10px;
    display: inline-block;
}

.hierarchy-context.show {
    left: calc(100% - 300px);
}

.cs-logo {
    width: 100%;
}

/*----grid-view-css-----*/
.map-legend {
    float: left;
    text-align: left;
}


.timer-component {
    position: absolute;
    top: 70px;
    right: 10px;
}

.strict-container .block-container,
.strict-container .widget-container,
.strict-container .widget,
.strict-container .widget .segment,
.strict-container .widget-wrapper>div {
    /*min-height: inherit;*/
    min-height: inherit;
    overflow: hidden;
}


.strict-container .widget-container>div {
    min-height: 100% !important;
}

.dashboard-items {
    max-height: calc(60% - 60px);
    /* padding-top: 5%; */
    overflow-y: auto;
}

.ant-select {
    width: 200px;
}

/* .menu-icon {
    background: white;
} */

.menu-icon:after path {
    background: red;
}

.user-icon {
    text-align: center;
    padding: 20% 0 10% 0;
}

.user-icon div {
    display: inline-block;
    width: 75px;
    height: 75px;
    background: #166fee;
    border-radius: 50% !important;
    padding: 10px;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .user-icon div {
    background: #009530;
}

.SE-theme-color .brand-header-username {
    color: #009530;
}

.SE-theme-color #custom-select-label {
    background: #009530 !important;
}

.MCD-theme-color .user-icon div {
    background: #da291c;
}

.MCD-theme-color .brand-header-username {
    color: #da291c;
}

.MCD-theme-color #custom-select-label {
    background: #da291c !important;
}

.user-icon div span {
    font-size: 3rem;
    text-transform: capitalize;
    color: white !important;
}

#custom-select-label {
    color: #ffffff !important;
    background: #F44067 !important;
    border-radius: 4px 0px 0px 4px;
    padding: 7px;
    font-size: 1rem;
    margin-left: 10px;
}

.bottom-logos {
    bottom: 75px;
    left: 40px;
    position: absolute;
    width: inherit;
}

.menu-images {
    display: flex;
    justify-content: center;
    /*display: inline-block;*/
    /*margin: 0 0 0 15px;*/
}

.customer-logo-images {
    text-align: center;
}

.customer-logo-images.OEM {
    height: auto;
}

.filter-footer-divider{
    margin: 8px 0;
}

.filter-footer-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 30px;
    padding: 0px 8px 4px;
    gap: 0px 8px;
    margin: 12px 0 4px 0px; 
}

.filter-footer-container .outlined,
.filter-footer-container .outlined span,
.filter-footer-container .outlined:hover
{
    border-color: black !important;
    color: black;
}

.filter-select-option-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.select-box-tag-render-item{
    background-color: #f5f5f5 !important;
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 0 4px;
    margin: 0 8px;
    border-radius: 4px;
    line-height: 22px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    cursor: default;
}

.filter-select-option-container .ant-checkbox{
    top:0;
}

.filter-select-option-container  .pointer-events-none{
    pointer-events: none;
}

.filter-footer-container .filter-button{
    display: flex !important;
    align-items: center !important;
    height: 28px !important;
    font-size: 12px !important;
}