.login-form {
    background: url("/src/imgs/login_screen.jpg");
}

.login-form-container {
    padding: 45px !important;
    max-width: 500px;
    box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
}

.login-instruction {
    height: 23px;
    font-family: 'Nunito', Roboto, sans-serif;
    font-size: 19.8px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.65px;
    color: #444444;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form-container input {
    height: 55px;
    border-radius: 2px;
    border: solid 0.6px rgba(208, 208, 208, 0.5);
    background-color: #e5e9ed !important;
}

.login-form-container .message {
    font-size: 12px;
}

.submit-login {
    margin-top: 50px;
    width: 100%;
    height: 53px;
    border-radius: 2px;
    background-color: #f44067;
    color: white !important;
}

.submit-login,
.submit-login:focus,
.submit-login:active {
    background: #f44067 !important;
    color: white !important;
}

.fluid .submit-login {
    color: white !important;
}

.submit-login * {
    color: white;
}

#forgot-password-trigger {
    margin: 10px;
    cursor: pointer;
    display: block;
    text-align: end;
    color: black;
    text-decoration: none;
}

#new-forgot-password-trigger {
    margin: 10px 10px 10px 2px;
    cursor: pointer;
    display: block;
    text-align: justify;
    color: #0075A3 !important;
    text-decoration: none;
}

#error-msg{
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    display: block;
    color:rgb(239, 0, 0) !important
}

.login-form .form-input-icon {
    position: absolute;
    top: 12px;
    left: 8px;
    width: 38px;
}

#otp-page-icon{
    margin-right: 8px;
}

.otp-source-dropdown{
    width: 70% !important;
    height: 45px;
}

.otp-source-dropdown .ant-select-selector{
    border: none !important;
    background: #F4F4F4 !important;
    text-align: left !important;
    font-size: 19px !important;
    height: 45px !important;
    display: flex !important;
    align-items: center !important;
}

#otp-source-generate-btn{
    color: white !important;
    background: #F44067;
    border-radius: 3px;
    font-weight: 100;
    width: 25%;
    font-size: 16px;
    padding: 0;
}

.otp-source-container{
    display: flex;
    grid-gap: 10px;
    margin-bottom: 25px;
}

.otp-container{
    margin-top: 60px;
}

.code-input-area .ui.fluid.input>input{
    background: #F4F4F4 !important;
    border: none;
    padding-left: 15px !important;
    font-size: 18px;
    height: 45px;
}

.submit-otp-container button{
    width: 100px;
    font-size: 18px !important;
    font-weight: 100 !important;
    height: 45px !important;
    line-height: 0px !important;
}