.app-header {
    height: 50px;
    width: 100%;
    /*background: #174e9e;*/
    /*display: grid;*/
    /*grid-template-columns: 85% 15%;*/
    text-align: right;
}

.app-header * {
    color: #5e5e5e !important;
    background: none;
    text-transform: capitalize;
    border: none;
}

.app-header .ui.steps {
    margin: 3px 0;
    width: 100%;
}

.app-header .ui.steps .step {
    padding: 13px 2rem;
}

.app-header .ui.steps .step.selected {
    background: #1986d2;
    color: white;
}

.app-header .ui.steps .step.selected::after {
    background: #1986d2;
    color: white;
}

.user-actions-username {
    font-weight: bold;
    font-size: 1.34em;
    color: white;
    margin-right: 1.5rem;
}

.logout-button {
    cursor: pointer;
}

.user-actions {
    position: relative;
    top: 45%;
    float: right;
    margin-right: 1.5rem;
}

.user-actions-menu {
    z-index: 3000 !important;
}

.ui.circular.label,
.ui.circular.labels .label {
    border-radius: 500rem !important;
}

.user-alerts {
    display: inline-block;
}

.user-alert-icon {
    position: relative;
    top: -7px;
    right: 32px;
    width: 5px;
    font-size: 0.69em !important;
    cursor: pointer;
}

.connect-title {
    font-size: 1.6rem;
    color: white;
    position: absolute;
    left: 50%;
    font-weight: bold;
    transform: translate(-50%);
    padding: .7rem;
}

.user-actions i {
    margin-right: 1.5rem;
    cursor: pointer;
    font-size: 1.4rem;
}

.ant-btn-primary span {
    color: white !important;
}

.alerts-modal .ant-modal-body {
    max-height: 60vh;
    overflow-y: scroll;
}

.screen-heading {
    /* color: #166fee !important; */
    float: left;
    top: 25%;
    /*left: 15px;*/
    position: relative;
}

.pos {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    align-items: center;
}

.app-filter.pos .hierarchy-context {
    margin: 10px 0px 10px 10px !important;
}

.app-filter.pos .hierarchy-context .ant-select .ant-select-selector {
    border-radius: 0 1px 1px 0 !important;
    border: none;
    text-align: left;
}

.app-filter.pos .hierarchy-context .ant-select .ant-select-selector:hover {
    border: none;
}

.brand-header-wrapper {
    height: 70px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    background-color: #F44067;
    z-index: 1;
    overflow: hidden;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .brand-header-wrapper {
    background-color: #ffffff !important;
}

.SE-theme-color .brand-header-wrapper .text-white {
    color: #242424 !important;
}

.MCD-theme-color .brand-header-wrapper {
    background-color: #da291c !important;
}

.MCD-theme-color .brand-header-wrapper .text-white {
    color: #fff !important;
}

.brand-header-item {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
}

.brand-header-item div {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.85);
}

.brand-header-item-last {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.85);
}

.brand-header-details-wrapper {
    text-align: center;
}

.brand-header-item-wrapper {
    cursor: pointer;
}

.brand-header-username {
    color: #F44067;
    font-size: 14px !important;
}

.brand-header-name {
    font-size: 24px !important;
    font-weight: 500;
}

.cs-brand-logo {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: contain;
}

.cs-zenatix-logo {
    width: 200px;
}

.brand-header-wrapper .dropdown-arrow-icon {
    font-size: 14px !important;
}

.session-expired-modal .ant-modal-body {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    text-align: center;
}

.session-expired-modal .session-expired-message {
    font: normal normal normal 18px/22px Nunito !important;
    letter-spacing: 0px;
    color: #626469;
    margin-bottom: 12px;
}

.session-expired-modal .session-expired-btn {
    background: #F44067 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #FFFFFF !important;
    border: none;
    width: 61px !important;
    height: 29px !important;
}

/*---------------------- SE Demo user Specific theme changes -------------*/

.SE-theme-color .cs-brand-logo,
.MCD-theme-color .cs-brand-logo {
    box-shadow: 0px 3px 6px #00000029;
}

.brand-header-item.open-source-link a,
.brand-header-item.open-source-link a:hover {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px !important;
}

.SE-theme-color.session-expired-modal .session-expired-btn {
    background: #009530 0% 0% no-repeat padding-box;
}

.MCD-theme-color.session-expired-modal .session-expired-btn {
    background: #da291c 0% 0% no-repeat padding-box;
}