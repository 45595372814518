.add-control-section {
    height: 100%;
}


.add-control-section .schedules-container{
    height: calc(100% - 60px);
}

.add-control-section .schedules-edit-container{
    height: calc( 100% - 20px ) ;
    overflow-y: auto;
}

.control-duplicate-delete-icon {
    cursor: pointer;
}

.add-control-section .schedules-outer-container {
    height: 100%;
    width: 100%;
}

.add-control-section .schedules-inner-container {
    overflow-y: auto;
}

.add-control-section .frequency-container{
    min-height: 150px;
    margin-top: 0;
    padding: 1em 0.5em;
}

.add-control-section .event-cards-container{
    width: 100%;
    overflow-x: auto;
}

.add-control-section .arrow-container {
    height: 100px;
    text-align: center;
}

.add-control-section .MCD-theme-color .remove-icon svg path{
    fill: #da291c;
}