.App {
  /* text-align: center; */
background: #fefefe;
}

.App *, .modals * {
  /*border-radius: 0 !important;*/
  color: #5f5f5f;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  position: relative;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.control-status-table .ant-table-filter-trigger{
  margin-bottom: -1px !important;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.dropdown .menu .item .text {
    font-size: smaller !important;
}

.session-control {
  display: grid;
}

.Toastify > div{
  top: 85vh !important;
  left: 40px !important;
  font-size: 1rem;
  font-weight: bold;
  width: 400px !important;
}

.Toastify__toast-icon{
  margin-inline-end: 10px !important;
  height: 15px;
}

.Toastify__toast-body{
  margin-right: 10px !important;
  font-weight: 100 !important;
  line-height: 20px;
  color: #757575 !important;
}

.Toastify__close-button--light{
  min-height: calc(var(--toastify-toast-min-height) - 16px) !important;
}

.Toastify__toast{
  border-radius: 10px !important;
  padding: 8px 12px 8px 5px;
  cursor: default !important;
} 

.Toastify__toast-body div{
  font-size: 14px !important;
}