.grey-button {
    background-color: #aaaaaa !important;
    color: white !important;
    border: 0 !important;
    border-radius: .5em !important;
}

.light-grey-button {
    background-color: #f5f5f5 !important;
    color: black !important;
    border: 0 !important;
    border-radius: .5em !important;
}

.red-button {
    background-color: #f44067 !important;
    color: white !important;
    border: 0 !important;
    border-radius: .5em !important;
}

.red-light-button {
    background-color: #f4406742 !important;
    color: #f44067 !important;
    border: 0 !important;
    border-radius: .5em !important;
}

.yellow-button {
    text-align: center;
    color: #FAAD14 !important;
    background-color: #faad144f !important;
    border: 0 !important;
    border-radius: .5em !important;
}

.green-button {
    text-align: center;
    color: #4CAF50 !important;
    background-color: #4caf4f36 !important;
    border: 0 !important;
    border-radius: .5em !important;
}

.change-btn-color-red{
    color: #da291c !important;
    background-color: rgb(218, 41, 28, 0.16) !important;
}

.grey-button-outline {
    border: 1px solid #5e5e5e !important;
    color: #5e5e5e !important;
    border-radius: .5em !important;
}

.red-button-outline {
    border: 1px solid #f44067 !important;
    color: #f44067 !important;
    border-radius: .5em !important;
}

.blue-button {
    text-align: center;
    color: #fff !important;
    background-color: #1890FF !important;
    border: 0 !important;
    border-radius: .5em !important;
}

.blue-button-outline {
    border: 1px solid #1890FF !important;
    color: #1890FF !important;
    border-radius: .5em !important;
}


.div-button {
    cursor: pointer;
    height: 32px;
    padding: 6px 15px 6px 15px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
}

.buttons-group {
    border-radius: 10px;
}

.transparent-button {
    text-align: center;
    color: #f44067 !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: .5em !important;
    box-shadow: none !important;
}

.new-ui .ant-btn,
.ant-pagination-item-link,
.ant-pagination-item,
.ant-table-filter-dropdown-btns .ant-btn {
    border-radius: .5em !important;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .red-button,
.SE-theme-color .blue-button {
    background-color: #009530 !important;
}

.SE-theme-color .red-button-outline,
.SE-theme-color .blue-button-outline {
    border: 1px solid #009530 !important;
    color: #009530 !important;
}

.SE-theme-color .transparent-button {
    color: #009530 !important;
}

.SE-theme-color .red-light-button,
.SE-theme-color .yellow-button {
    background-color: rgba(0, 149, 48, 0.2) !important;
    color: #009530 !important;
}

.MCD-theme-color .red-button,
.MCD-theme-color .blue-button {
    background-color: #da291c !important;
}

.MCD-theme-color .red-button-outline,
.MCD-theme-color .blue-button-outline {
    border: 1px solid #da291c !important;
    color: #da291c !important;
}

.MCD-theme-color .transparent-button {
    color: #da291c !important;
}

.MCD-theme-color .red-light-button,
.MCD-theme-color .yellow-button {
    background-color: rgb(218, 41, 28, 0.16) !important;
    color: #da291c !important;
}

.red-light-button.delete-btn-icon {
    border: none !important;
    background: transparent !important;
}

.download-icon-color svg path,
.edit-icon-color svg path,
.plus-icon-color svg path {
    fill: #f44067;
}

.SE-theme-color .download-icon-color svg path,
.SE-theme-color .edit-icon-color svg path,
.SE-theme-color .plus-icon-color svg path,
.SE-theme-color .plus-icon-yellow svg path {
    fill: #009530;
}

.MCD-theme-color .download-icon-color svg path,
.MCD-theme-color .edit-icon-color svg path,
.MCD-theme-color .plus-icon-color svg path,
.MCD-theme-color .plus-icon-yellow svg path {
    fill: #da291c;
}

.plus-icon-yellow svg path {
    fill: #FAAD14;
}

.plus-icon-black svg path {
    fill: black;
}