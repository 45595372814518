.tnc-model .ant-modal-content {
    border-radius: 5px !important;
}

.tnc-model .tnc-model-body {
    padding: 10px 0;
}

.tnc-model .tnc-model-icon {
    display: flex;
    justify-content: center;
}

.tnc-model .tnc-model-heading {
    color: #F44067;
    font-size: 25px !important;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.tnc-model .SE-change-color {
    color: #009530;
}

.tnc-model .MCD-change-color {
    color: #da291c;
}

.tnc-model .tnc-model-content {
    color: #242424;
    display: flex;
    justify-content: center;
    font-size: 18px !important;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 5px;
    line-height: 22px;
}

.tnc-model .tnc-model-btn,
.tnc-model .tnc-model-btn:hover {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px;
    background: #F44067;
    color: white !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 10px 20px;
}

.tnc-model .SE-change-btn-color,
.tnc-model .SE-change-btn-color:hover {
    background: #009530;
    color: white !important;
}

.tnc-model .MCD-change-btn-color,
.tnc-model .MCD-change-btn-color:hover {
    background: #da291c;
    color: white !important;
}