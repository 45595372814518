.custom-error-block{
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.custom-error-img{
    width: 310px;
    position: absolute;
    left: -120px;
    bottom: 0px;
}

.custom-error-404{
    font-size: 140px !important;
    font-weight: 700;
    color: #F44067; 
    line-height: 200px;
}

.custom-error-content1{
    font-size: 38px !important;
    font-weight: 600; 
    margin-bottom: 4px;
}

.custom-error-content2{
    font-size: 13px !important;
}

.custom-error-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}