.terms-and-conditions-modal{
    box-shadow: 0px 3px 17px #00000029;
    border: 1px solid #F44067;
    border-radius: 12px;
    padding-bottom: 0 !important;
}

.terms-and-conditions-modal .ant-modal-content{
    border-radius: 12px !important;
    height: 425px;
    padding: 0 !important;
}

.terms-and-conditions-modal .ant-modal-body{
    padding: 24px 24px 10px 24px;
}

.opt-out-modal .ant-modal-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: none !important;
    padding: 20px 0px;
    grid-gap: 20px;
    margin-top: 0;
}

.opt-out-modal{
    border: none;
    width: 320px !important;
}

.opt-out-modal .ant-modal-content{
    border-radius: 20px !important;
    box-shadow: 5px 6px 6px #00000029;
    width: 350px;
    height: 180px;
    padding: 0;
}

.opt-out-modal .ant-modal-body{
    text-align: center;
    padding: 30px 24px 15px;
    font-size: 17px !important;
    line-height: 1.3;
}

.opt-out-modal .ant-modal-body div{
    font-size: 17px !important;
}

.opt-out-modal .ant-modal-footer>button{
    background: #F44067 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    color: white;
    border: none;
    font-size: 11px;
    width: 90px;
}

.terms-and-conditions-modal .accept-modal-btn,
.terms-and-conditions-modal .accept-modal-btn:hover {
    background: #f44067 0% 0% no-repeat padding-box;
    margin-right: 16px;
    border: none;
    width: 125px;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 12px;
}

.terms-and-conditions-modal .cancel-modal-btn,
.terms-and-conditions-modal .cancel-modal-btn:hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #f44067 !important;
    border: none;
    outline: 2px solid #f44067;
    outline-offset: -2px;
    width: 125px;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 12px;
}

.terms-and-conditions-modal .terms-modal-title {
    color: #f44067;
    font-size: 23px;
    font-weight: 500;
    border-bottom: 1px solid #707070;
    margin: 0;
    padding-bottom: 0.5em;
}

.cancel-modal-btn,
.cancel-modal-btn:hover{
    color: #f44067 !important;
    background: white !important;
    border: 1px solid #F44067 !important;
}

.SE-theme-color .cancel-modal-btn,
.SE-theme-color .cancel-modal-btn:hover{
    color: #009530 !important;
    background: white !important;
    border: 1px solid #009530 !important;
}

.MCD-theme-color .cancel-modal-btn,
.MCD-theme-color .cancel-modal-btn:hover{
    color: #da291c !important;
    background: white !important;
    border: 1px solid #da291c !important;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color.terms-and-conditions-modal .terms-modal-title {
    color: #009530 !important;
}
.SE-theme-color.terms-and-conditions-modal .accept-modal-btn,
.SE-theme-color.terms-and-conditions-modal .accept-modal-btn:hover {
    background: #009530 0% 0% no-repeat padding-box;
}
.SE-theme-color.terms-and-conditions-modal .cancel-modal-btn,
.SE-theme-color.terms-and-conditions-modal .cancel-modal-btn:hover {
    color: #009530 !important;
    outline: 2px solid #009530 !important;
}
.SE-theme-color.terms-and-conditions-modal{
    border: 1px solid #009530 !important;
}

.MCD-theme-color.terms-and-conditions-modal .terms-modal-title {
    color: #da291c !important;
}
.MCD-theme-color.terms-and-conditions-modal .accept-modal-btn,
.MCD-theme-color.terms-and-conditions-modal .accept-modal-btn:hover {
    background: #da291c 0% 0% no-repeat padding-box;
}
.MCD-theme-color.terms-and-conditions-modal .cancel-modal-btn,
.MCD-theme-color.terms-and-conditions-modal .cancel-modal-btn:hover {
    color: #da291c !important;
    outline: 2px solid #da291c !important;
}
.MCD-theme-color.terms-and-conditions-modal{
    border: 1px solid #da291c !important;
}

.terms-and-conditions-modal .terms-modal-description {
    color: #707070;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.terms-and-conditions-modal .terms-modal-text {
    height: 260px;
    display: block;
    overflow: auto;
    color: #707070;
    line-height: 1.35;
    font-size: 16px;
}

.terms-and-conditions-modal .ant-modal-footer {
    text-align: center;
    padding: 0 0 25px;
    border-top: none;
}

#terms-content p {
    margin-top: 0;
}

.download-tnc{
    color: rgb(31, 115, 235);
    grid-gap: 2px;
    cursor: pointer;
    font-size: 14px !important;
}