body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 10px;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: brown !important;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: brown !important;
}

html,
body,
#root,
.App {
  height: 100%;
  font-size: 0.8rem;
}

body span {
  word-break: break-word;
}

.ui.tabular.menu .item {
  background: 0 #e0e1e2;
  border-right: 1px solid #747576;
}

.ui.tabular.menu .item:hover {
  background-color: #119dff;
}

.App *,
.modals * {
  /*border-radius: 0 !important;*/
  color: #5e5e5e;
}

.ant-modal-content {
  border-radius: 2px !important;
}

.ant-modal-confirm-body {
  display: flex;
}

.ant-modal-confirm-body>span.anticon {
  margin-right: 0.4em;
}

.ant-modal-confirm-body>span.anticon>svg {
  height: 2em;
}

.ant-modal-confirm-content {
  text-align: center;
}

.ant-modal-confirm-btns {
  float: none !important;
  text-align: center;
}

.ant-modal-confirm-btns .ant-btn {
  border-radius: 28px;
  min-width: 80px;
}

.ui.active.transition.visible.dimmer {
  background: #fff8 !important;
}

.ui.dimmer .ui.loader:after {
  border-color: #174e9dff #174e9d88 #174e9d88 !important;
}

.ant-select-selection__placeholder {
  color: #c3c3c3;
}

table tr td {
  cursor: default !important;
}

:root {
  --primary-table-filter-color: #F44067;
}

.ant-input,
.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url],
.ui.input>input{
  font-family: 'Nunito', sans-serif !important;
}