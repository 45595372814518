.instant-control-table .ant-table-cell{
    border-bottom: none !important;
    border-right: none !important;
}

.instant-control-table{
    min-height: 80% !important;
    background: none !important;
}

.instant-control-table .ant-table-pagination.ant-pagination{
    width: 100%;
    display: flex;
    justify-content: center;
}

.instant-control-table .ant-pagination-item{
    border: 1px solid black;
    height: 25px;
    min-width: 25px;
    border-radius: 5px !important;
}

.instant-control-table .ant-pagination-item-active{
    background-color: #F44067;
    color: white !important;
    border: 1px solid #F44067;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .instant-control-table .ant-pagination-item-active{
    background-color: #009530;
    border: 1px solid #009530;
}
.SE-theme-color .table-icons{
    border: 1.5px solid #009530;
    color: #009530 !important;
}

.MCD-theme-color .instant-control-table .ant-pagination-item-active{
    background-color: #da291c;
    border: 1px solid #da291c;
}
.MCD-theme-color .table-icons{
    border: 1.5px solid #da291c;
    color: #da291c !important;
}

.instant-control-table .ant-pagination-prev .ant-pagination-item-link{
    border: 1px solid black;
}

.instant-control-table .ant-pagination-next .ant-pagination-item-link{
    border: 1px solid black;
}

.instant-control-table .ant-table{
    display: table;
    width: 100%;
    padding: 10px 15px 0 !important;
}

.instant-control-table .ant-table-container{
    border: none !important;
}

.instant-control-table .ant-table-thead>tr>th{
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: center !important;
    background: white !important;
}

.instant-control-table .ant-table-cell-row-hover{
    background-color: #f6f6f6 !important;
}

.instant-control-table .ant-table-row > .ant-table-cell:first-child{
    font-weight: 500;
    font-size: 14px;
    padding: 8px 5px 8px 10px;
}

.instant-control-table .ant-table-row > .ant-table-cell:not(:first-child){
    font-size: 15px;
    text-align: center !important;
}

.instant-control-table .ant-table table { 
    border-spacing: 0 10px; 
    border-top: none !important;
}

.instant-control-table .ant-table-tbody{
    background: #F6F6F6;
}

.instant-control-table .ant-table-thead > tr > th {
    padding: 2px 10px 5px 0;
    line-height: 20px;
}

.instant-control-table .ant-table-tbody > tr > td {
    padding: 8px 5px;
}

.table-icons{
    border: 1.5px solid #F44067;
    width: 25px;
    display: flex;
    height: 25px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    color: #F44067 !important;
    box-shadow: 0px 3px 6px #00000029;
}

.instant-control-table .ant-table-row > .ant-table-cell:last-child{
    height: 100%;
    width: 98px;
    padding: 8px 10px 8px 5px;
}

.instant-control-table .ant-table-thead .ant-table-cell:last-child{
    display: none; 
    background: white;
}

/* .instant-control-table .ant-table-thead > tr > th:nth-child(3){
    padding: 2px 40px 5px 0;
}

.instant-control-table .ant-table-thead > tr > th:nth-child(6){
    padding: 2px 40px 5px 0;
}   

.instant-control-table .ant-table-thead > tr > th:nth-child(8){
    padding: 2px 40px 5px 0;
} */

.instant-control-table .ant-form-item-control::before, .ant-form-item-control::after{
    content: unset;
}

.tooltip-ui{
    background: white;
    color: black;
    border-radius: 5px;
    height: 15px;
    font-size: 10px !important;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-number{
    border: none;
    width: 75px !important;
    background-color: #F5F5F5;
}

.input-number .ant-input-number-handler-wrap{
    opacity: 1;
    border-left: none;
    background: none;
    height: 28px;
}

.input-number .ant-input-number-input-wrap{
    padding: 0 4px;
    border: 1px solid #707070;
    box-shadow: 0px 2px 3px #00000029;
    background-color: #FFFFFF;
    margin-right: 26px;
    border-radius: 5px;
    height: 25px;
}

.input-number .ant-input-number-input-wrap input{
    padding: 0 5px;
    height: 25px;
    font-size: 14px;
    position: relative;
    bottom: 1px;
}

.custom-dropdown-instant-control{
    /* top: 2px; */
}

.instant-control-form-item .ant-form-item-control-input-content{
    display: flex;
}

.disabled-custom-dropdown-instant-control{
    color: #ABABAB !important;
}

.custom-dropdown-instant-control .ant-select-selector{
    height: 25px !important;
    border: 1px solid #707070 !important;
    box-shadow: 0px 2px 5px #00000029 !important;
    font-size: 14px !important;
    padding: 0 10px !important;
    margin-bottom: 2px;
    border-radius: 6px !important;
}

.disabled-custom-dropdown-instant-control .ant-select-selector{
    border: 1px solid #B3B3B3 !important;
}

.custom-dropdown-instant-control .ant-select-arrow{
    font-size: 11px;
}

.custom-dropdown-instant-control .ant-select-selector .ant-select-selection-item{
    position: absolute;
    bottom: -4px;
    width: 85%;
}

.multilingual-translation .custom-dropdown-instant-control .ant-select-selector .ant-select-selection-item{
    position: relative;
    bottom: 3px;
}

.input-number .ant-input-number-handler{
    background-color: #F5F5F5;
    border-top: none;
    border: 1px solid #F44067 !important;
    margin-bottom: 3px !important;
    border-radius: 4px !important;
    height: 45%;
    width: 80%;
}

.input-number .ant-input-number-handler-up-inner svg{
    fill: red; 
    width: 20px; 
    height: 14px; 
    position: relative; 
    bottom: 2px;
}

.input-number .ant-input-number-handler-down-inner svg{
    fill: red; 
    width: 20px; 
    height: 14px; 
    position: relative; 
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .input-number .ant-input-number-handler {
    border: 1px solid #009530 !important;
}
.SE-theme-color .input-number .ant-input-number-handler-up-inner svg,
.SE-theme-color .input-number .ant-input-number-handler-down-inner svg {
    fill: #009530 !important; 
}
.SE-theme-color .action-icons{
    border: 2px solid #009530 !important;
}
.SE-theme-color .delete-antd-icon{
    border: 2px solid #009530;
    color: #009530 !important;
}

.MCD-theme-color .input-number .ant-input-number-handler {
    border: 1px solid #da291c !important;
}
.MCD-theme-color .input-number .ant-input-number-handler-up-inner svg,
.MCD-theme-color .input-number .ant-input-number-handler-down-inner svg {
    fill: #da291c !important; 
}
.MCD-theme-color .action-icons{
    border: 2px solid #da291c !important;
}
.MCD-theme-color .delete-antd-icon{
    border: 2px solid #da291c;
    color: #da291c !important;
}

.instant-control-table .ant-table-filter-column{
    justify-content: flex-start !important;
    font-size: 17px !important;
    min-width: 170px;
}

.instant-control-table .ant-table-column-title{
    flex: none !important;
}

.instant-control-table .ant-dropdown-trigger:hover{
    background: none !important;
}

.tooltip-icon + .custom-tooltip-icon{
    display: none;
}

.tooltip-icon:hover + .custom-tooltip-icon{
    display: block;
}

.custom-tooltip{
    border: 1px solid #707070;
    border-radius: 2px;
    font-size: 12px !important;
    width: fit-content;
    padding: 0 3px;
    background: white;
    color: #5F5F5F !important;
    letter-spacing: 0px;
    position: absolute;
    bottom: 35px;
    left: 10px;
    z-index: 9999;
}

.disabled-input-number{
    opacity: 0.5;
    pointer-events: none;
}

.instant-control-table .ant-pagination-prev,
.instant-control-table .ant-pagination-next{
    height: 25px;
    min-width: 25px;
}

.instant-control-table .ant-pagination-prev .ant-pagination-item-link,
.instant-control-table .ant-pagination-next .ant-pagination-item-link{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    height: 25px;
}

.instant-control-table .ant-pagination li>a{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    height: 25px;
    border-radius: 5px !important;
}

.delete-override-modal{
    width: 285px !important;
}

.delete-override-modal .ant-modal-content {
    padding: 20px 20px !important;
    border-radius: 2px !important;
}

.override-duration-modal .ant-modal-close{
    right: 38px;
    top: 29px;
}

.override-duration-modal .ant-modal-close:hover{
    background: white;
}

.override-duration-modal .ant-modal-close-x{
    font-size: 14px;
}

.delete-override-modal .ant-modal-footer,
.override-duration-modal .ant-modal-footer{
    display: flex;
    border-top: none !important;
    margin-top: 5px !important;
    justify-content: center;
    grid-gap: 10px;
}

.delete-override-modal .ant-modal-body{
    padding: 10px 25px;
    line-height: 21px;
    text-align: center;
    font-size: 18px !important;
}

.delete-override-modal .ant-modal-footer button,
.override-duration-modal .ant-modal-footer button{
    border-radius: 20px;
    height: 25px;
    width: 75px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.delete-override-modal .ant-modal-footer .cancel-modal-btn{
    border-color: #F44067;
    display: flex;
    align-items: center;
    justify-content: center;
}

.override-duration-modal .ant-modal-title{
    color: #F44067;
    font-size: 21px !important;
    font-weight: 400;
}

.number-input-arrow-icon {
    width: 11px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .number-input-arrow-icon svg polygon{
    fill: #009530;
}
.SE-theme-color.override-duration-modal .ant-modal-title{
    color: #009530;
}
.SE-theme-color .override-duration-selectbox .ant-select-selector,
.SE-theme-color.delete-override-modal .ant-modal-footer .cancel-modal-btn {
    border-color: #009530 !important;
}

.MCD-theme-color .number-input-arrow-icon svg polygon{
    fill: #da291c;
}
.MCD-theme-color.override-duration-modal .ant-modal-title{
    color: #da291c;
}
.MCD-theme-color .override-duration-selectbox .ant-select-selector,
.MCD-theme-color.delete-override-modal .ant-modal-footer .cancel-modal-btn {
    border-color: #da291c !important;
}

.override-duration-modal .ant-modal-header{
    border-bottom: none;
    padding: 0;
}

.override-duration-modal .ant-modal-footer button{
    width: 100px !important;
}

.override-duration-modal .ant-modal-body{
    padding: 10px 0;
    margin-bottom: 15px;
}

.override-duration-modal .ant-modal-content{
    border-radius: 10px !important;
    padding: 20px 24px !important;
}

.override-duration-modal{
    width: 350px !important;
}

.override-duration-selectbox{
    width: 100%;
    box-shadow: 0px 3px 6px #0000001A;
    height: 35px;
}

.override-duration-selectbox .ant-select-selector{
    border-radius: 5px !important;
    height: 35px !important;
    border-color: #F44067 !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
}

.override-duration-selectbox .ant-select-arrow{
    color: black !important;
}

.delete-antd-icon{
    border: 2px solid rgb(242, 67, 105);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    font-size: 14px;
    padding: 3px 3px 3px;
    bottom: 2px;
    position: relative;
    color: #F44067 !important;
}

.action-icons{
    border: 2px solid #F24369;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
}