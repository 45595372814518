.login-form-footer{
    height: 11lvh;
    width: -webkit-fill-available;
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5lvw 0 2.5lvw;
}

#login-form-footer-content{
    color: white !important;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
}

.new-login-form-container {
    width: 24vw;
}

.new-login-form-container .field{
    margin: 5px 0 1em !important;
}

.new-login-form-container input,
.new-otp-container input{
    font-size: 18px;
    padding-left: 7px !important;
    height: 35px;
    border-radius: 5px !important;
    border: 1px solid #626469 !important;
    background-color: #fff !important;
}

.login-logo-instruction-container{
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.5lvh 2.5lvw 2.5lvw 3lvh;
}

.login-infomation{
    position: absolute;
    bottom: 11lvh;
    width: 100%;
}

.transparent-green-cover{
    background: #008A16;
    height: 4lvh;
    opacity: 0.4;
}

.green-cover{
    background: #3DCD58;
    height: 4lvh;
}

.new-submit-login,
#new-submit-login  {
    font-size: 14 !important;
    margin-bottom: 15px !important;
    width: 100%;
    height: 35px;
    border-radius: 5px !important;
    background-color: #008A16 !important;
    color:white !important;
}

.new-submit-login * {
    color: white;
}

.new-login-screen-spinner .ant-spin-dot-item{
    background-color: #008A16;
}

#new-otp-source-generate-btn{
    margin-right: 0;
    color: black !important;
    background: white;
    border-radius: 5px;
    font-weight: 500;
    width: 30%;
    font-size: 16px;
    padding: 0;
    border: 1px solid #626469;
    height: 35px;
}

.new-submit-otp-container button{
    width: 100px;
    font-size: 18px !important;
    font-weight: 500 !important;
    height: 35px !important;
    line-height: 0px !important;
    width: 100%;
}

.new-otp-source-container{
    display: flex;
    grid-gap: 10px;
    margin-bottom: 20px;
}

.new-otp-source-dropdown{
    width: 65% !important;
    height: 35px;
    margin-right: 10px;

}

.new-otp-source-dropdown .ant-select-selector{
    border: 1px solid #626469  !important;
    background: #fff !important;
    text-align: left !important;
    font-size: 18px !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 5px !important;
    padding-left: 8px !important;
    padding-right: 0px !important;

}

.new-login-instruction {
    font-family: 'Nunito', sans-serif;
    font-size: 26px;
    /* font-weight: normal; */
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    /* letter-spacing: 1.65px; */
    color: #444444;
    text-align: center;
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    /* margin-left: 10px; */
}

#new-OTP-input{
    height: 35px;
    font-weight: 500;
    padding-left: 9px !important;
}

.eu-login-form-title {
    font-size: 24px !important;
    font-weight: 500;
}

.new-otp-source-dropdown .dropdown-arrow-icon{
    height: 100% !important;
    width: 24px !important;
    right: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: inherit !important;
    
}

#new-forgot-password-trigger {
    margin: 10px 0 10px 2px !important;
    cursor: pointer;
    display: block;
    text-align: justify;
    font-size: 14px;
    color: #0075A3 !important;
    text-decoration: none;
}

.login-form .ui.button{
    font-family: 'Nunito', sans-serif;
}

#color-imp-red{
    color: #DE1500 !important;
}

@media screen and  (max-width: 1366px) {

    .eu-login-form-title {
        font-size: 20px !important;
    }

    .login-form .new-login-form-container form div{
        font-size: 12px;
    }

    .new-otp-source-container{
        margin-bottom: 10px;
    }

    .new-login-form-container input,
    .new-otp-container input,
    .new-otp-source-dropdown .ant-select-selector,
    #new-otp-source-generate-btn,
    #new-OTP-input,
    .new-submit-otp-container button
    {
        font-size: 14px !important;
    }

    #login-form-footer-content{
        font-size: 9px;
    }
}
