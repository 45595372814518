.otp-section-modal .ant-modal-body {
    display: flex;
    justify-content: center;
}

.verify-otp-card {
    text-align: center;
}

.verify-otp-card .card-sub-header {
    color: #f44067 !important;
    font-size: 18px;
}

.verify-otp-card .otp-container {
    justify-content: center;
    margin: 20px 0px 10px 0;
}

.verify-otp-card .otp-input {
    margin-right: 16px;
    width: 3.5rem !important;
    height: 3.5rem;
    font-size: 2rem;
    background-color: #f5f5f5;
    border: none;
    color: #818486;
    border-radius: 7px;
}

.verify-otp-card .receive-otp-info {
    color: #707070;
    font-size: 15px;
    margin-bottom: 5px;
}

.verify-otp-card .resend-otp-txt,
.verify-otp-card .retry-count-txt {
    color: #1890fb !important;
    font-size: 15px;
}

.verify-otp-card .verifyotp-btn-groups {
    margin: 40px 0px 20px 0px;
}

.verify-otp-card .submit-otp-btn,
.verify-otp-card .submit-otp-btn:hover {
    background: #f44067 0% 0% no-repeat padding-box;
    margin-right: 16px;
    border: none;
    border-radius: 22px!important;
    box-shadow: 0 3px 6px #00000029;
    font-size: 12px!important;
    height: 32px!important;
    width: 120px;
}

.verify-otp-card .clear-otp-btn,
.verify-otp-card .clear-otp-btn:hover {
    color: #f44067 !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    outline: 2px solid #f44067;
    outline-offset: -2px;
    border: none;
    border-radius: 22px!important;
    box-shadow: 0 3px 6px #00000029;
    font-size: 12px!important;
    height: 32px!important;
    width: 120px;
}

.reset-modal-space .ant-space-item {
    align-self: center;
}

.reset-modal-space .reset-modal-title {
    width: 260px;
    text-align: center;
    margin-top: 0;
    line-height: 1;
    font-size: 20px;
    color: #5F5F5F;
}

.reset-modal-space .reset-modal-sub-title {
    display: block;
    text-align: center;
    line-height: 1.1;
    font-size: 14px;
    color: #3F3D56;
}

.verify-email-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    grid-gap: 25px;
}

.verification-heading {
    font-size: 20px;
}

.card-wrapper {
    width: 100%;
    box-shadow: 0px 3px 17px #00000029;
    border-radius: 12px;
}

.card-wrapper .ant-card-head {
    /* over-riding antd-card-header styles */
    background: #f44067 0% 0% no-repeat padding-box;
    border-radius: 12px;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0px;
    font-size: 24px;
    height: 56px;
    line-height: 22px;
}
.header-wrapper hr {
    margin-top: 0;
    margin-bottom: 20px;
    border: 1px solid #707070;
}
.header-wrapper{
    text-align: left;
}

.header-wrapper img {
    margin-left: -15px;
}

.card-wrapper .ant-card-body{
    padding: 24px;
}

.form-btn:disabled{
    opacity: 0.3;
    color: #fff;
}

.card-wrapper .ant-card-head-title{
    font-size: 25px !important;
    font-weight: 500;
    color: white !important;
}

.new-card-wrapper {
    width: 100%;
    border-radius: 0;
    border: none;
    background: none;
    position: fixed;
    bottom: 11lvh;
}


.new-card-wrapper .ant-card-body{
    padding: 0;
}

.new-card-wrapper .ant-row{
    background: white;
}

.new-card-wrapper-heading{
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 20px;
}

.verify-otp-card .new-card-sub-header {
    color: black !important;
    font-size: 16px;
    display: block;
    text-align: left;
}

.new-verify-email-container{
    display: flex;
    justify-content: center;
    align-items: baseline;
    grid-gap: 5px;
    flex-direction: column;
}

.new-verification-heading{
    font-size: 22px;
    line-height: 24px;
}
  
.new-otp-container .otp-input{
    margin-right: 16px;
    width: 34px !important;
    height: 40px;
    font-size: 26px !important;
    border: 1px solid #626469 !important;
    color: black;
    border-radius: 4px !important;
    padding-left: 2px !important;
}

.verify-otp-card .new-verifyotp-btn-groups {
    margin: 20px 0px;
    display: flex;
}

.new-gen-otp-btn,
.new-gen-otp-btn:hover {
    background-color: #009530 !important;
    border-color: #009530 !important;
    margin-right: 16px;
    height: 32px !important;
    border-radius: 4px !important;
    color: white;
    width: 93%;
}

.new-gen-otp-btn:disabled{
    background: #009530;
    height: 32px !important;
    border-radius: 4px !important;
    opacity: 0.4;
    color: white;
    width: 93%;
}

@media screen and  (max-width: 1366px) {
    .new-verification-heading{
        font-size: 18px;
        line-height: 20px;
    }

    .verify-otp-card .new-card-sub-header{
        font-size: 14px;
    }

    .verify-otp-card .receive-otp-info, .verify-otp-card .resend-otp-txt,
     .verify-otp-card .retry-count-txt {
        font-size: 13px;
    }
   
    .new-otp-container .otp-input{
        margin-right: 12px;
        width: 30px !important;
        height: 36px;
        font-size: 24px !important;
    }
}