.m-point5 {
    margin: .5em;
}

/* ==============================================  Top  =====================================================*/

.mt-10 {
    margin-top: 10px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

/* ==============================================  Left  =====================================================*/

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 10px;
}

.ml-30 {
    margin-left: 30px;
}

/* ==============================================  right  =====================================================*/

.mr-5 {
    margin-right: 5px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-30 {
    margin-right: 30px;
}


/* ==============================================  bottom  =====================================================*/

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-30 {
    margin-bottom: 30px;
}


.cs-page-scroll {
    height: 100%;
    width: 100%;
    transition: transform 500ms ease-in-out 0s;
    outline: none;
}


.cs-actuator-input {
    width: 100%;
    border: none;
    padding: 0px 8px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
}

#cs-actuator-input-unit{
    font-size: 14px !important;
}

.cs-actuator-input:focus {
    outline: none;
    /* border: none; */
}

.cs-actuator-input:disabled {
    background-color: transparent !important;
}

.cs-actuator-input-active {
    background-color: #f5f5f5 !important;
}