.slide-right {
    -webkit-animation: slideIn .5s ease-in;
    -moz-animation: slideIn .5s ease-in;
    animation: slideIn .1s ease-in;
  }
  .slide-left {
    -webkit-animation: slideOut .5s ease-in;
    -moz-animation: slideOut .5s ease-in;
    animation: slideOut .1s ease-in;
  }
.rotate:hover .icon-wrapper-red{
    animation: scale .5s ease-in;
}

@keyframes scale {
  0% {
      transform:  rotate(0deg);

  }
  100% {
    transform: rotate(90deg);

  }
}

  @-webkit-keyframes slideOut {
    0% {
      transform: translateX(10px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideOut {
    0% {
        opacity: 0;
  
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
  
        transform: translateX(0);
    }
  }
  @keyframes slideOut {
    0% {
        opacity: 0;
  
        transform: translateX(10px);
    }
    100% {
      opacity: 1;

      transform: translateX(0);
    }
  }

  @-webkit-keyframes slideIn {
    0% {
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideIn {
    0% {
        opacity: 0;
  
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
  
        transform: translateX(0);
    }
  }
  @keyframes slideIn {
    0% {
        opacity: 0;
  
        transform: translateX(-10px);
    }
    100% {
      opacity: 1;

      transform: translateX(0);
    }
  }