.cpm-accordian-container{
    position: relative;
    width: 100%;
}

.cpm-accordian-container .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow{
    display: contents;
}

.cpm-accordian-container .ant-collapse {
    border: none;
}

.cpm-accordian-container .ant-collapse-header-text{
    font-size: 16px;
}

.cpm-accordian-container .ant-collapse-header{
    border-bottom : 0px solid white;
    background-color: #ffffff;
    border-radius: 15px !important;
    -webkit-box-shadow: 0 8px 6px -6px #00000029;
    -moz-box-shadow: 0 8px 6px -6px #00000029;
    box-shadow: 0 8px 6px -6px #00000029;
    z-index: 1;
}

.cpm-accordian-container .ant-collapse-item .ant-collapse-content {
    border-top-width: 0px;
    box-shadow: none;
    margin-top: -5px;
    border-radius: 0 0 15px 15px !important;
}

.cpm-accordian-container .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0 0 15px 15px !important;
    overflow-x: auto;
}