.dashboard-user-sessions-container {
    display: grid;
    grid-template-columns: minmax(275px, 10%) minmax(calc(100% - 275px), 90%);
    grid-template-rows: 100vh;
}

.dashboard-user-sessions-container-no-permission{
    height: 100%;
}

.no-permission{
    height: 100%;
    background: white !important;
    text-align: center;
}

.user-session-listing {
    background: white;
}

.user-session-listing .segment {
    margin: 0;
}

.session-information {
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 0 2%;
}

.user-session-listing a {
    float: right;
}

.dashboard-listing {
    height: 100%;
    position: relative;
    background: white;
}

.dashboard-listing .menu-images {
    text-align: center;
    /*height: 5%;*/

    margin-top: 15px;
}

.dashboard-screen-label {
    font-size: 1.4rem;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid lightgrey;
}

.dashboard-screen-label:hover h3,
.dashboard-screen-label.active h3,
.single-screen.active h3,
.single-screen.active i {
    color: #F44067 !important;
}

.single-screen {
    padding: 10px 25px;
    align-items: center;
    cursor: pointer;
    display: flex;
}

.single-screen>* {
    margin-right: 15px !important;
}

.single-screen.active::after {
    content: '';
    width: 6px;
    height: 24px;
    position: absolute;
    right: 0;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #F44067;
}

.single-screen {
    position: relative;
    border-bottom: .5px solid #1f1f1f1c;
    border-right: .5px solid #1f1f1f1c;
}

.single-screen.active {
    background-color: #f5f5f5;
}

.single-screen>i {
    font-size: 17px;
    font-weight: lighter;
    color: #5e5e5e;
    margin-top: -8px;
    margin-left: 20px;
}

.single-screen > h3 {
    font-size: 18px;
}

.Toastify>div {
    top: 5rem;
    font-size: 1rem;
    font-weight: bold;
}

.main-content>div {
    height: calc(100% - 150px);
    overflow: auto;
}

.main-content>div::-webkit-scrollbar {
    display: none;
}

.dashboard-listing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 80px);
    background: white;
    margin-top: 3px;
}

.bottom-logos {
    align-self: center;
    /* margin-bottom: 100px; */
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .dashboard-screen-label:hover h3,
.SE-theme-color .single-screen:hover h3,
.SE-theme-color .dashboard-screen-label.active h3,
.SE-theme-color .single-screen.active h3,
.SE-theme-color .single-screen.active i,
.SE-theme-color .single-screen.active>*,
.single-screen.active h3,
.single-screen:hover h3 {
    color: #242424 !important;
}

.SE-theme-color .single-screen.active::after {
    background-color: #009530 !important;
}

.SE-theme-color .single-screen > * {
    color: #626469 !important;
}

.MCD-theme-color .dashboard-screen-label:hover h3,
.MCD-theme-color .single-screen:hover h3,
.MCD-theme-color .dashboard-screen-label.active h3,
.MCD-theme-color .single-screen.active h3,
.MCD-theme-color .single-screen.active i,
.MCD-theme-color .single-screen.active>*{
    color: #242424 !important;
}

.MCD-theme-color .single-screen.active::after {
    background-color: #da291c !important;
}

.MCD-theme-color .single-screen > * {
    color: #626469 !important;
}

.ant-picker-clear {
  display: none;
  pointer-events: none;
}

.select-down-arrow-icon {
    width: 18px;
    margin-left: 5px;
    float: right;
    margin-top: 2px;
}

.ant-modal.controls-action-modal .ant-modal-confirm-body-wrapper,
.ant-modal.controls-action-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    text-align: center;
}

.ant-modal.controls-action-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-title {
    font-size: 20px;
}

.ant-modal.controls-action-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-content {
    margin-inline-start: 18px !important;
    font-size: 14px !important;
}

/* ------ Antd Table Filter Dropdown styles overrides ------ */
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-primary,
.ant-table-filter-dropdown .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-table-filter-color);
    border-color: var(--primary-table-filter-color);
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-primary:hover,
.ant-table-filter-dropdown .ant-checkbox-wrapper:hover .ant-checkbox-checked.ant-checkbox .ant-checkbox-inner,
.ant-table-filter-dropdown .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover .ant-checkbox-checked.ant-checkbox .ant-checkbox-inner,
.ant-table-filter-dropdown .ant-checkbox-wrapper .ant-checkbox-checked.ant-checkbox:hover .ant-checkbox-inner {
    background-color: var(--primary-table-filter-color);
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link:hover {
    color: var(--primary-table-filter-color);
}

.ant-table-filter-dropdown .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after,
.ant-table-filter-dropdown .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-table-filter-dropdown .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--primary-table-filter-color);
}

.ant-table-filter-dropdown .ant-checkbox-checked::after {
  border: 1px solid var(--primary-table-filter-color);
}
/* ------ Antd Table Filter Dropdown styles overrides ------ */