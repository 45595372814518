.App *,
.modals * {
    color: unset !important;
}

.new-ui .ant-select-dropdown,
.new-ui .ant-select-selector,
.new-ui .ant-form-item-control-input {
    border-radius: .5em !important;
}

.ant-picker-panel {
    border-radius: .5em !important;
}

.new-ui .ant-select-selector {
    border-radius: .5em !important;
}

.white .ant-picker-input {

    color: white !important;
}

.white .ant-picker-input>input {
    color: white !important;
}

.new-ui .ant-collapse-item {
    border: 0 !important;
}


.white .ant-picker-suffix {
    color: white !important;
}

.new-ui .white .anticon anticon-edit {
    color: white !important;
}

.new-ui .border-red .ant-select-selector {
    background-color: #f5f5f5 !important;
    border: 1px solid #f44067 !important;
    border-radius: .5em !important;
}

.new-ui .red .ant-select-selector {
    background-color: #f44067 !important;
    color: white !important;
}

.new-ui .red .ant-select-arrow {
    color: white !important;
}

.black .ant-picker-input input {
    color: black !important;
}

.black .ant-picker-suffix {
    color: black !important;
}

.black .ant-picker-input input::-webkit-input-placeholder {
    color: black !important;
}

.white .ant-picker-input input::-webkit-input-placeholder {
    color: white !important;
}


.new-ui .first {
    border-right: none !important;
    border-bottom-left-radius: .5em !important;
    border-top-left-radius: .5em !important;
}

.new-ui .last {
    border-left: none !important;
    border-bottom-right-radius: .5em !important;
    border-top-right-radius: .5em !important;
}

.new-ui .buttons-group {
    border: 1px solid #f44067;
}

.new-ui .buttons-group .ant-btn:hover {
    border-color: #f44067;
    background-color: #f44067;
    color: white !important;
    border-radius: .5em !important;
}

.new-ui .selected-view {
    border-color: #f44067;
    background-color: #f44067 !important;
    color: white !important;
    border-radius: .5em !important;
}

.custom-datepicker .ant-picker-input>input {
    background-color: #f8f8f8ce;
    border-radius: .5em;
    padding: 5px;
    font-size: 18px;
}

.custom-datepicker.ant-picker {
    padding-left: 0;
}

.custom-datepicker.ant-picker .ant-picker-input > input {
    text-align: center;
}

.custom-datepicker-small .ant-picker-input>input {
    background-color: #f8f8f8ce;
    border-radius: .5em;
    padding: 5px;
    font-size: 14px;
}


.red .ant-picker:hover,
.ant-picker-focused {
    border-radius: .5em !important;
    /* border: none !important; */
    box-shadow: none !important;
}

.ant-picker-range {
    border-radius: .5em !important;
    border: none !important;
    box-shadow: none !important;
    background-color: #f5275436 !important;
}

.ant-picker-range .ant-picker-active-bar {
    background-color: #f52754 !important;
}

.red .ant-picker-input>input {
    color: #f52754 !important;
}

.red .ant-picker-separator {
    color: #f52754 !important;
}

.red .ant-picker-suffix {
    color: #f52754 !important;
}

.red .ant-picker-input input::-webkit-input-placeholder {
    color: #f52754 !important;
}


.ant-picker-panel-container {
    border-radius: 1em !important;
}

.new-ui .site-calendar-demo-card {
    width: 252px;
    /* border: 1px solid #f0f0f0; */
    /* border-radius: 2px; */
}

.new-ui .site-calendar-demo-card .ant-picker-calendar .ant-picker-panel {
    border: 1px solid #f5f5f5 !important;
}

.new-ui .ant-popover-inner {
    border-radius: 1em !important;
}

.new-ui .ant-popover .ant-input-group,
.new-ui .curve .ant-input-group-addon>button {
    border-top-right-radius: .5em !important;
    border-bottom-right-radius: .5em !important;
}

.new-ui .ant-popover .ant-input-group,
.new-ui .curve .ant-input-group>input {
    border-top-left-radius: .5em !important;
    border-bottom-left-radius: .5em !important;
}

.new-ui .curve .ant-input-group-addon>button {
    border: 1px solid #F44067;
    background-color: #F44067;
    color: white !important;
}

.new-ui .ant-checkbox-group {
    max-height: 150px !important;
    display: flex;
    flex-direction: column;
}

.new-ui .override .ant-picker-cell::before {
    height: 0px !important;
}

.new-ui .override .ant-picker-calendar .ant-picker-panel .ant-picker-content {
    width: 100px !important;
}

.new-ui .override .ant-picker-calendar-mini .ant-picker-content {
    width: 100px !important;
}

.new-ui .ant-table-thead>tr>th .anticon-filter,
.new-ui .ant-table-thead>tr>th .ant-table-filter-icon {
    /* top: 50% !important */
}

.new-ui .ant-tag {
    border: none;
    font-weight: bolder;
    padding: .3em 1em .3em 1em;
    border-radius: 1em;
}

.new-ui .ant-tag-green {
    color: #52c41a !important;
}

.new-ui .ant-tag-red {
    color: #f5222d !important;
}

.new-ui .ant-table-filter-dropdown {
    border-radius: .5em !important;
}

.new-ui .ant-table-filter-dropdown .ant-dropdown-menu {

    border-radius: .5em !important;
}

.new-ui .ant-table-filter-dropdown-btns {
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em;
}

.new-ui .ant-table-filter-dropdown-btns>button {
    border-radius: .5em;
}

.new-ui .anticon-filter {
    color: rgb(66, 66, 66) !important;
}

.new-ui .ant-table-thead>tr>th {
    color: rgb(66, 66, 66) !important;
    font-weight: bolder !important;
    /* background: #f5f5f5 !important; */
}


/* .Toastify__toast--warning {
    background: #efb25f;
    color: #555;
}

.Toastify__toast--success {
    background: #26d650;
    color: white !important;
}

.Toastify__toast--error {
    background: #e74848;
    color: white !important;
}

.Toastify__toast--info {
    background: #174e9e;
    color: #d4d4d5 !important;
} */

.submit-login {
    color: white !important;
}

.ant-table-thead>tr>th .anticon-filter,
.ant-table-thead>tr>th .ant-table-filter-icon {
    position: unset;
}

.ant-table-filter-dropdown {
    border-radius: 8px;
}

.ant-table-filter-dropdown-btns {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.cs-grey-select .ant-select-selector,
.grey-select .ant-select-selector,
.grey-select .ant-select-item-option,
.grey-select .ant-select-selection-search,
.grey-option,
.grey .ant-select-item-option {
    background-color: #f5f5f5 !important;

}

.grey-option,
.cs-grey-select .ant-select-item-option {
    margin: 0 !important;
}

.grey-option:hover {
    background-color: #e4e4e4 !important;
}

.ant-picker {
    border-radius: .5em;
}

.ant-btn {
    border-radius: .5em;
}

.ant-btn-dangerous {
    color: #F44067 !important;
    border-radius: .5em;
    border: 0.15em solid;
}

.ant-checkbox-group {
    display: flex;
    flex-direction: unset;
}

.active .anticon-filter {
    color: #FAAD14 !important;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .new-ui .border-red .ant-select-selector,
.SE-theme-color .new-ui .buttons-group {
    border: 1px solid #009530 !important;
}

.SE-theme-color .new-ui .red .ant-select-selector,
.SE-theme-color .ant-picker-range .ant-picker-active-bar {
    background-color: #009530 !important;
}

.SE-theme-color .new-ui .buttons-group .ant-btn:hover,
.SE-theme-color .new-ui .selected-view {
    border-color: #009530 !important;
    background-color: #009530 !important;
}

.SE-theme-color .new-ui .curve .ant-input-group-addon>button {
    border: 1px solid #009530 !important;
    background-color: #009530 !important;
}

.SE-theme-color .ant-btn-dangerous,
.SE-theme-color .red .ant-picker-input>input,
.SE-theme-color .red .ant-picker-input>input,
.SE-theme-color .red .ant-picker-separator,
.SE-theme-color .red .ant-picker-suffix,
.SE-theme-color .red .ant-picker-input input::-webkit-input-placeholder {
    color: #009530 !important;
}

.SE-theme-color .ant-picker-range {
    background-color: rgba(0, 149, 48, 0.2) !important;
}

.SE-theme-color.cs-modal .ant-select:hover .ant-select-selector,
.SE-theme-color.cs-modal .ant-select-focused .ant-select-selector,
.SE-theme-color .screen-container .ant-select:hover .ant-select-selector,
.SE-theme-color .screen-container .ant-select-focused .ant-select-selector {
    border-color: #009530 !important;
    box-shadow: 0 0 0 2px rgba(0, 149, 48, 0.2) !important;
}

/* Antd Date range picker style override */
.SE-theme-color .ant-picker:hover,
.SE-theme-color .ant-picker.ant-picker-focused {
  border-color: #009530;
}

.SE-theme-color .ant-picker-focused.ant-picker-range {
  border-color: #009530;
  box-shadow: 0 0 0 2px rgba(0, 149, 48, 0.2) !important;
}

.SE-theme-color .ant-picker-focused.ant-picker-range .ant-picker-active-bar,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background: #009530;
}

.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #009530;
}

.SE-theme-color.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #009530;
}

.SE-theme-color.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: rgba(0, 149, 48, 0.2);
}

.SE-theme-color.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}

.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.SE-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
  background: #e6ffee;
}

.SE-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.SE-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.SE-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover::after {
  border-top: 1px dashed #0fd34d !important;
  border-bottom: 1px dashed #0fd34d !important;
}

.SE-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.SE-theme-color tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after {
  border-right: 1px dashed #0fd34d !important;
}

.SE-theme-color tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
  border-left: 1px dashed #0fd34d !important;
}

.SE-theme-color .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, 
.SE-theme-color .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: #D3F8DF;
}

.MCD-theme-color .new-ui .border-red .ant-select-selector,
.MCD-theme-color .new-ui .buttons-group {
    border: 1px solid #da291c !important;
}

.MCD-theme-color .new-ui .red .ant-select-selector,
.MCD-theme-color .ant-picker-range .ant-picker-active-bar {
    background-color: #da291c !important;
}

.MCD-theme-color .new-ui .buttons-group .ant-btn:hover,
.MCD-theme-color .new-ui .selected-view {
    border-color: #da291c !important;
    background-color: #da291c !important;
}

.MCD-theme-color .new-ui .curve .ant-input-group-addon>button {
    border: 1px solid #da291c !important;
    background-color: #da291c !important;
}

.MCD-theme-color .ant-btn-dangerous,
.MCD-theme-color .red .ant-picker-input>input,
.MCD-theme-color .red .ant-picker-input>input,
.MCD-theme-color .red .ant-picker-separator,
.MCD-theme-color .red .ant-picker-suffix,
.MCD-theme-color .red .ant-picker-input input::-webkit-input-placeholder {
    color: #da291c !important;
}

.MCD-theme-color .ant-picker-range {
    background-color: rgb(218, 41, 28, 0.16) !important;
}

.MCD-theme-color.cs-modal .ant-select:hover .ant-select-selector,
.MCD-theme-color.cs-modal .ant-select-focused .ant-select-selector,
.MCD-theme-color .screen-container .ant-select:hover .ant-select-selector,
.MCD-theme-color .screen-container .ant-select-focused .ant-select-selector {
    border-color: #da291c !important;
    box-shadow: 0 0 0 2px rgb(218, 41, 28, 0.16) !important;
}

/* Antd Date range picker style override */
.MCD-theme-color .ant-picker:hover,
.MCD-theme-color .ant-picker.ant-picker-focused {
  border-color: #da291c;
}

.MCD-theme-color .ant-picker-focused.ant-picker-range {
  border-color: #da291c;
  box-shadow: 0 0 0 2px rgb(218, 41, 28, 0.16) !important;
}

.MCD-theme-color .ant-picker-focused.ant-picker-range .ant-picker-active-bar,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background: #da291c;
}

.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #da291c;
}

.MCD-theme-color.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #da291c;
}

.MCD-theme-color.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: rgb(218, 41, 28, 0.16);
}

.MCD-theme-color.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}

.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.MCD-theme-color.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
  background: #ffe6e6;
}

.MCD-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.MCD-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.MCD-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover::after {
  border-top: 1px dashed #D30F0F !important;
  border-bottom: 1px dashed #D30F0F !important;
}

.MCD-theme-color .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.MCD-theme-color tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after {
  border-right: 1px dashed #D30F0F !important;
}

.MCD-theme-color tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
  border-left: 1px dashed #D30F0F !important;
}

.MCD-theme-color .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, 
.MCD-theme-color .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: #F8D3D3;
}