body {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;

}

.custom-input {
    cursor: pointer;
    outline: none;
    border: none;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #f5f5f5;
    border-radius: .5em;
    text-align: center
}

.custom-input input {
    text-align: center;
}

.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
}

.custom-input .ant-input-number-handler-wrap {
    display: none;
}

.threshold-btw-line {
    width: 20px;
    height: 2px;
    margin: 0px 10px;
    background-color: #a5a5a5;
    border-radius: 4px;
}

.custom-center {
    display: flex;
    justify-content: center;
}

.display-column {
    display: flex;
    flex-direction: column;
}

.display-row {
    display: flex;
    flex-direction: row;
}


.close-icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: 10px;
}

.red-border-date-picker {
    background: #f5f5f5;
    border: 1px solid #F44067;
    border-radius: .5em;
    color: black !important;
}

/* ==============================================  display  =====================================================*/


.d {
    display: flex;
}

.jc-c {
    justify-content: center;
}

.jc-sb {
    justify-content: space-between;
}

.jc-se {
    justify-content: space-evenly;
}

.jc-sa {
    justify-content: space-around;
}

.jc-fe {
    justify-content: flex-end;
}

.jc-fs {
    justify-content: flex-start;
}

.ai-center {
    align-items: center;
}

.txt-center {
    text-align: center;
}

/* ==============================================  border radius  =====================================================*/


.br-1 {
    border-radius: 1em;
}

.br-point5 {
    border-radius: .5em;
}


/* ==============================================  Width nd height  =====================================================*/


.wh-30 {
    width: 40px;
    height: 40px;
}

.w-100 {
    width: 100%;
}

/* ==============================================  COLOUR  =====================================================*/

.color-grey {
    background-color: #f5f5f5;
    box-shadow: #f5f5f5;
}

.color-red {
    background-color: #F44067 !important;
}

.text-red {
    color: #F44067 !important;
}

.d-flex {
    display: flex;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .text-red {
    color: #009530 !important;
}
.SE-theme-color .icon-wrapper-shade:hover,
.SE-theme-color .icon-wrapper-small:hover,
.SE-theme-color .icon-small-wrapper:hover,
.SE-theme-color .icon-wrapper:hover {
    background-color: #009530;
    box-shadow: 0px 0px 10px -2px rgba(0, 149, 48, 0.86);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 149, 48, 0.86);
    -moz-box-shadow: 0px 0px 10px -2px rgba(0, 149, 48, 0.86);
}
.SE-theme-color .icon-wrapper-small {
    border: 1px solid #009530;
    color: #009530 !important;
    background-color: rgba(0, 149, 48, 0.15);
}

.MCD-theme-color .text-red {
    color: #da291c !important;
}
.MCD-theme-color .icon-wrapper-shade:hover,
.MCD-theme-color .icon-wrapper-small:hover,
.MCD-theme-color .icon-small-wrapper:hover,
.MCD-theme-color .icon-wrapper:hover {
    background-color: #da291c;
    box-shadow: 0px 0px 10px -2px rgb(218, 41, 28, 0.86);
    -webkit-box-shadow: 0px 0px 10px -2px rgb(218, 41, 28, 0.86);
    -moz-box-shadow: 0px 0px 10px -2px rgb(218, 41, 28, 0.86);
}
.MCD-theme-color .icon-wrapper-small {
    border: 1px solid #da291c;
    color: #da291c !important;
    background-color: rgb(218, 41, 28, 0.15);
}

.text-green {
    color: #4CAF50 !important;
}

.text-yellow {
    color: #FAAD14 !important;
}

.text-white {
    color: white !important;
}

/* ==============================================  Holiday - modal  =====================================================*/

.holiday-model {
    max-height: 650px;
    border-radius: 1em;
    background-color: white;
    width: 250px;
    position: absolute;
    z-index: 5;
    box-shadow: 0px 0px 10px -2px rgba(138, 138, 138, 0.6);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(138, 138, 138, 0.6);
    -moz-box-shadow: 0px 0px 10px -2px rgba(138, 138, 138, 0.6);
}

.custom-modal-header {
    background-color: #f5f5f5;
    padding: 1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}

.custom-modal-header>h2 {
    color: #5f5f5f !important;
}

.holiday-input {
    font-size: 16px;
    border: none;
    background-color: #fafafa;
    border-radius: .5em;
    padding: .5em;
    /* border-bottom: 1px solid #F44067; */
}

.mode:hover {
    color: #F44067 !important;

}

.mode {
    cursor: pointer;
}

.selected-mode {
    color: #F44067 !important;
    padding: 3px 5px 3px 5px !important;
    border-radius: .3em;
    border: 1px solid #F44067;
}

.holiday-input:focus {
    outline: none;
}

.holiday-date {
    font-size: 18px;
}

.sub-title {
    color: rgb(158, 158, 158) !important;
    margin: 0;
    padding: 0;
    font-size: 14px;
}

/* ==============================================  Icon wrapper  =====================================================*/
.cs-medium-icon {
    font-size: 18px;
}

.icon-wrapper {
    background-color: #f5f5f5;
    border-radius: 50%;
    padding: 1em;
    cursor: pointer;
}

.icon-small-wrapper {
    background-color: #f5f5f5;
    border-radius: 50%;
    padding: .5em;
    cursor: pointer;
    height: 34px;
    width: 34px;
}

.icon-wrapper-small {
    border: 1px solid #eb2f96;
    color: #eb2f96 !important;
    background-color: #eb2f9618;
    border-radius: 50%;
    padding: .5em;
    cursor: pointer;
    height: 36px;
    width: 36px;
}

.icon-wrapper-shade {
    border: 1px solid #eb2f96;
    color: #eb2f96 !important;
    background-color: #eb2f9618;
    border-radius: 50%;
    padding: 1em;
    cursor: pointer;
}

.icon-wrapper-white {
    font-size: 12px;
    background-color: white;
    border-radius: 50%;
    padding: 1em;
    cursor: pointer;
    box-shadow: 0px 0px 10px -2px rgba(202, 202, 202, 0.86);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(202, 202, 202, 0.86);
    -moz-box-shadow: 0px 0px 10px -2px rgba(202, 202, 202, 0.86);
}

.icon-wrapper-red {
    background-color: #F44067;
    border-radius: 50%;
    padding: 1.5em;
    color: white !important;
    cursor: pointer;
}

.icon-wrapper-red-text {
    color: #F44067 !important;
    border-radius: 20%;
    background-color: #f5f5f5;
    padding: .3em;
    cursor: pointer;
}

.icon-wrapper-white:hover {
    background-color: #F44067;
    color: white !important;
    box-shadow: 0px 0px 10px -2px rgba(247, 64, 64, 0.86);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(247, 64, 64, 0.86);
    -moz-box-shadow: 0px 0px 10px -2px rgba(247, 64, 64, 0.86);
}


.icon-wrapper-shade:hover,
.icon-wrapper-small:hover,
.icon-small-wrapper:hover,
.icon-wrapper:hover {
    background-color: #F44067;
    color: white !important;
    box-shadow: 0px 0px 10px -2px rgba(247, 64, 64, 0.86);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(247, 64, 64, 0.86);
    -moz-box-shadow: 0px 0px 10px -2px rgba(247, 64, 64, 0.86);
}

.icon-small-wrapper:hover svg path,
.icon-wrapper-small:hover svg path {
    fill: #fff;
}


/* ==============================================  Holiday - list view  =====================================================*/

.date-list-view {
    font-size: 12px;
    border-radius: 1em;
    background-color: #f5f5f5;
    padding: 1em;
}

.list-view-wrapper {
    position: relative;
    height: 85vh;
}

.date-list-view-delete {
    cursor: pointer;
    font-size: 12px;
    border-radius: 1em;
    text-align: center;
    justify-content: space-around;
    color: white !important;
    background-color: #F44067;
    padding: 1em;
}

.list-add-button {
    z-index: 10;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

/* ==============================================  Display - css  =====================================================*/

.wrapper {
    margin-bottom: 30px;
    width: 100%;
    background-color: white;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -2px rgba(202, 202, 202, 0.86);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(202, 202, 202, 0.86);
    -moz-box-shadow: 0px 0px 10px -2px rgba(202, 202, 202, 0.86);
}

.wrapper-active {
    border: 1px solid #F44067 !important;
}

.back-grey {
    background-color: #f5f5f5 !important;
    border-radius: 10px;
    margin-top: 10px;
    padding: 1em 2em 1em 2em;
}

.red-hr {
    background-color: #F44067;
    margin-top: 5px;
    margin-bottom: 10px;
    height: 1px;
    opacity: .7;
    padding: 0 5px 0 5px 0;
    width: 100%;
}

.horizontal-hr {
    width: 1px;
    background-color: #F44067;
    opacity: .3;
    margin: 10px 0px 10px 0px
}

.overlay {
    cursor: not-allowed;
    z-index: 90;
    background-color: white;
    opacity: .8;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.days {
    cursor: pointer;
    border-radius: .5em;
    padding: .5em !important;
    margin: 0 !important;
    margin-right: 5px !important;
}

.days:hover {
    background-color: #F44067;
    color: white;
}

/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .wrapper-active {
    border: 1px solid #009530 !important;
}

.SE-theme-color .wrapper .display-column .red-hr {
    background-color: #009530;
}

.SE-theme-color .days:hover {
    background-color: #009530;
}

.SE-theme-color .days-selected {
    border: 1px solid #009530;
}

.MCD-theme-color .wrapper-active {
    border: 1px solid #da291c !important;
}

.MCD-theme-color .wrapper .display-column .red-hr {
    background-color: #da291c;
}

.MCD-theme-color .days:hover {
    background-color: #da291c;
}

.MCD-theme-color .days-selected {
    border: 1px solid #da291c;
}

.days-selected {
    background-color: white;
    border: 1px solid #F44067;
    border-radius: .5em;
    padding: .5em;
}

.rc-progress-circle {
    width: 150px;
    height: 150px;
}

.cs-disable {
    opacity: .6;
    cursor: not-allowed !important;
}

.slider-wrapper {
    width: 100%;
    min-width: 150px;
    background-color: #f5f5f5;
    padding: 2px 6px;
}

.cs-modal {
    z-index: 9999999999;
}

.cs-modal .ant-modal-content {
    border-radius: 8px;
}

.cs-modal-title {
    font-size: 22px;
}

.cs-modal-sub-title {
    font-size: 12px;
}

.cs-modal-content {
    margin-top: 20px;
    min-height: 150px;
}


.event-icon {
    font-size: 16px;
}

.cs-freq-select {
    padding: 0px;
}

.cs-freq-select .ant-popover-arrow {
    display: none;
}

.cs-freq-select .ant-popover-inner-content {
    padding: 2px 10px;
}

.cs-time-passed-border {
    border-bottom: 4px dashed #FAAD14;
}

.cs-time-left-border {
    border-bottom: 4px dashed #1F1F1F;
    opacity: .5;
}


.cs-timeline .ant-timeline-item-tail {
    border-left: 2px solid black;
}

.cs-timeline .ant-timeline-item-content {
    top: -2px
}

.multi-widget-active {
    background-color: #f4406725;
    border-radius: 4px;
    padding: 10px 15px;
}

.multi-widget {
    padding: 10px 15px;
}

.cur {
    cursor: pointer;
}

.legend-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.cs-dis-none {
    display: none;
}

.freq-indicator {
    color: #F44067;
    background-color: #f5f5f5;
    padding: .4em;
    border-radius: .5em;
    border: 1px solid #f5f5f5;
    cursor: pointer;
}

.freq-indicator-active {
    border-radius: .5em;
    cursor: pointer;
    color: #F44067;
    background-color: #f5f5f5;
    padding: .4em;
    border: 1px solid #F44067;
}
/*---------------------- SE Demo user Specific theme changes -------------*/
.SE-theme-color .freq-indicator-active {
    color: #009530 !important;
    border: 1px solid #009530;
}

.MCD-theme-color .freq-indicator-active {
    color: #da291c !important;
    border: 1px solid #da291c;
}

.rbc-month-view .rbc-header>span {
    font-family: 'Nunito', 'Montserrat', sans-serif !important;
    font-weight: 700;
    text-transform: uppercase;
}


.top-header {
    position: sticky;
    top: 0;
    z-index: 20;
}

.top-header:first-child {
    left: 0;
    z-index: 26;
}

.second-header {
    position: sticky;
    top: 28px;
    z-index: 2;
}

.left-header {
    position: sticky;
    left: 0;
    background-color: white !important;
}

.second-left-header {
    position: sticky;
    left: 0;
    background-color: white !important;
}

.data-grid-container .data-grid {
    border-collapse: separate !important;
    border-spacing: 0px !important;
}

.cs-sheet table td {
    border: none;
    border-top: none !important;
    border-left: none !important;
    border-right: 1px solid #DDD !important;
    border-bottom: 1px solid #DDD !important;
}

.cs-sheet table {
    border: 1px solid #DDD !important;
    border-bottom: none !important;
    border-right: none !important;
}


@media screen and (min-width: 1440px) {
    .second-header {
        position: sticky;
        top: 36px;
        z-index: 2;
    }
}