 .rbc-event-content {
   color: white;
 }

 .rbc-month-view {
   border-radius: .5em;
   height: 80vh !important;
   border: none
 }

 .rbc-month-row {
   border: none !important;

 }


 .rbc-off-range-bg {
   opacity: .5 !important;
   background-color: transparent !important;
 }

 .rbc-off-range>a {
   opacity: .5;
 }

 .rbc-date-cell>a {
   margin-left: 10px;
   margin-top: 10px;

 }

 .rbc-date-cell>button {
  margin-left: 10px;

}

 .rbc-day-bg {
   margin: 1px;
   border-radius: .5em;
   border: 1px solid rgb(238, 238, 238) !important;
 }

 .rbc-day-bg:hover {
   box-shadow: 0px -3px 19px -2px rgba(138, 138, 138, 0.86);
   -webkit-box-shadow: 0px -3px 19px -2px rgba(138, 138, 138, 0.86);
   -moz-box-shadow: 0px -3px 19px -2px rgba(138, 138, 138, 0.86);
 }

 .rbc-row-content {
   pointer-events: none;
 }

 .rbc-row-content+.rbc-day-bg:hover {
   box-shadow: 0px -3px 19px -2px rgba(138, 138, 138, 0.86);
   -webkit-box-shadow: 0px -3px 19px -2px rgba(138, 138, 138, 0.86);
   -moz-box-shadow: 0px -3px 19px -2px rgba(138, 138, 138, 0.86);
 }

 .rbc-header {
   border: none !important;
 }

 .rbc-month-header>.rbc-header {
   padding-bottom: 1em;
   font-size: 16px;
   font-weight: 200;
   margin-bottom: -10px;
   color: rgb(148, 148, 148) !important;
 }

 .rbc-event {
   background-color: transparent !important;
 }

 .rbc-today {
   background-color: transparent !important;
 }

 .rbc-now {
   margin-top: 5px;
 }

 .rbc-date-cell {
   text-align: left !important;
 }

 .rbc-now>button {
  background-color: #f44067 !important;
  padding: 0.3em;
  color: #fff !important;
  font-weight: bold;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
 }

/*---------------------- SE Demo user Specific theme changes -------------*/
 .SE-theme-color .rbc-now>button {
  background-color: #009530 !important;
 }

 .MCD-theme-color .rbc-now>button {
  background-color: #da291c !important;
 }

 .year {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
 }

 .month {
   margin: 5px 5px 15px 5px;
 }

 .month-name {
   color: #000000;
   font-weight: bold;
 }

 .day {
   display: inline-block;
   width: 30px;
   height: 30px;
   text-align: center;
   line-height: 30px;
 }

 .date {
   margin: 2.5px;
   width: 25px;
   height: 25px;
   /* background: rgb(241, 241, 241); */
   border-radius: 50px;
   border: none;
   outline: none;
 }

 .date.in-month:hover {
   cursor: pointer;
   color: white !important;
   background: #F44067;
 }

 .today {
   color: white !important;
   background: #d45555;
 }

 .SE-theme-color .today {
  background: #009530;
}

.MCD-theme-color .today {
  background: #da291c;
}

 .event {
   color: white !important;
   background: #7dd455;
 }

/*---------------------- SE Demo user Specific theme changes -------------*/
 .SE-theme-color .event {
  background: #3dcd58;
 }
 .SE-theme-color .date.in-month:hover {
  background: #009530 !important;
 }

 .MCD-theme-color .event {
  background: #CD3D3D;
 }
 .MCD-theme-color .date.in-month:hover {
  background: #da291c !important;
 }

 .date.prev-month,
 .date.next-month {
   color: grey;
 }



 /* ==============================================  Week  =====================================================*/


 .week .rbc-event {
   /* background-color: rgba(255, 0, 0, 0.349) !important; */
   border: none;
   /* border-radius: 0; */
   /* border-left: 2px solid red; */
   /* border-top-right-radius: .5em; */
   /* border-bottom-right-radius: .5em; */
 }

 .week .rbc-event {
   /* height: 100% !important; */
 }

 .week .rbc-header>a>span {
   font-size: 16px;
   font-weight: 200;
   color: rgb(148, 148, 148) !important;
 }

 .week .rbc-allday-cell {
   display: none;
 }

 .week .rbc-time-view {
   border: none;
 }

 .week .rbc-timeslot-group,
 .rbc-time-content {
   border: none;
 }

 /* .week .rbc-time-header-content {

   border: 1px solid #DDD;
 } */
 .week .rbc-header {
   /* border-left: 1px solid #DDD !important; */
   padding: 1em;
   background-color: #f5f5f5;
 }

 .week .rbc-events-container {
   border-bottom: 1px solid #DDD;
 }

 .week .rbc-day-slot .rbc-events-container {
   margin: 0;
 }

 .rbc-time-content:last-child:last-child {
   border-right: 1px solid #DDD;
 }

 .week .rbc-time-header-content {
   border: none !important;
 }

 .week .rbc-now {
   margin: 0 !important;
 }

 .week .rbc-header:first-child {
   border-top-left-radius: 1em !important;
 }

 .week .rbc-header:last-child {
   border-top-right-radius: 1em !important;
 }

 .week .rbc-header+.rbc-today {
   background-color: #F44067 !important;
 }

 .week .rbc-header+.rbc-today>a>span {
   color: white !important;
 }

 .week .rbc-event-label {
   display: none;
 }

 .week .rbc-time-slot {
   flex: 0 0;
 }

 .week .rbc-time-header .rbc-overflowing {
   margin-right: 0px;
 }

 .week .rbc-time-header.rbc-overflowing {
   border: none;
 }

 .week .rbc-event {
   height: unset !important;
 }

 .week .title-red {
   /* padding: 5px 3px 5px 3px !important; */
 }


 .week .rbc-event-label {
   /* display: none; */
 }

 .week .rbc-event {
   width: 100% !important;
   left: 0 !important;
   padding: 0;
   margin: 0;
 }

 .week .title {
   margin-top: 2px;
   height: 1px;
   border: none;
   /* background-color: blue; */
 }

 .week .rbc-current-time-indicator {
   display: none;
 }

 .week .colour-F44067 {
   background-color: #F44067 !important;

 }

 .week .colour-FF0B00 {
   background-color: #FF0B00 !important;

 }

 .week .colour-36E02A {
   background-color: #36E02A !important;

 }

 .week .colour-007CB4 {
   background-color: #007CB4 !important;
 }

 .week .colour-00CEFF {
   background-color: #00CEFF !important;
 }

 .week .colour-FAAD14 {
   background-color: #FAAD14 !important;
 }

 .week .colour-E66B0E {
   background-color: #E66B0E !important;
 }

 .week .colour-C04C00 {
   background-color: #C04C00 !important;
 }

 .week .colour-790500 {
   background-color: #790500 !important;
 }

 /* ==============================================  cSS  =====================================================*/

 .custom-month-height {
   height: 68vh !important;
 }

 .large .rbc-date-cell>a {
   font-size: 16px !important;
 }

 .title-red {
   border-radius: .3em;
   padding: 1px 3px 1px 3px;
   /* background-color: rgba(240, 60, 60, 0.178); */
   /* outline: red; */
 }

 .colour-F44067 {
   color: #F44067 !important;
   border-left: 4px solid #F44067;

 }

 .colour-FF0B00 {
   color: #FF0B00 !important;
   border-left: 4px solid #FF0B00;

 }

 .colour-36E02A {
   color: #36E02A !important;
   border-left: 4px solid #36E02A;

 }

 .colour-007CB4 {
   color: #007CB4 !important;
   border-left: 4px solid #007CB4;
 }

 .colour-00CEFF {
   color: #00CEFF !important;
   border-left: 4px solid #00CEFF;
 }

 .colour-FAAD14 {
   color: #FAAD14 !important;
   border-left: 4px solid #FAAD14;
 }

 .colour-E66B0E {
   color: #E66B0E !important;
   border-left: 4px solid #E66B0E;
 }

 .colour-C04C00 {
   color: #C04C00 !important;
   border-left: 4px solid #C04C00;
 }

 .colour-790500 {
   color: #790500 !important;
   border-left: 4px solid #790500;
 }

 .rbc-event:focus {
   outline: none !important;
   border: none !important;
 }